import React from 'react';

const BallsGame = () => {
    return (
        <div>
            <iframe src="ballsgame/index.html" width='100%' height='534px' frameBorder="0" className={'my-5'}/>
        </div>
    );
};

export default BallsGame;
