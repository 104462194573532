import BallsGame from "../../Components/Game/BallsGame";

export const Days = [
    {
        id: 1, content:
            <>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls autoPlay>
                        <source src="assets/videos/elfrock.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls autoPlay>
                        <source src="assets/videos/elfrock.mp4" type="video/mp4"/>
                    </video>
                </div>
                <p className={'text-xs'}>Forrás: <a href={"https://hu.pinterest.com/pin/3166662229833853/"}
                                                    rel={"noreferrer noopener"}
                                                    target={"_blank"}>https://hu.pinterest.com/pin/3166662229833853/</a>
                </p>
            </>
    },
    {
        id: 2, content:
            <>
                <h3>Idén is megrendezésre került az egyre nagyobb népszerűségnek
                    örvendő Junior Projektmenedzsment Verseny.</h3>
                <p>Az alábbi videókkal nosztalgiázzunk együtt és örüljünk közösen annak,
                    hogy ilyen pozitív pályán van a projektmenedzserek utánpótlása.</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Y0fpoUYpMhw"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/Y0fpoUYpMhw"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    },
    {
        id: 3, content:
            <>
                <h3>2022-ben indult útnak a PMSZ Tonik blog sorozatunk.</h3>
                <p>Korántsem gondoljuk, hogy a fa alatt szakmai blogokat kellene olvasnotok. Dehát hol van még
                    Karácsony??? :)</p>
                <p>Addig is, ha időtök van, pörgessétek át a tartalmakat egy kávé vagy egy gin tonic mellett. Nektek
                    ajánljuk most a legolvasottabb blogjainkat. Jó olvasást kívánunk!</p>
                <div className={'flex gap-3 flex-wrap md:flex-nowrap'}>
                    <a href={"https://pmsz.hu/hireink/pmsz-tonik-folyamatos-tanyerporgetes/"}
                       rel="noreferrer noopener"
                       target={"_blank"}>
                        <img src={"assets/tonic-1.jpg"} alt={"PMSz Tonik - Folyamatos tányérpörgetés"}/>
                        <div className="btn-primary mt-2">Elolvasom</div>
                    </a><br/>
                    <a href={"https://pmsz.hu/hireink/pmsz-tonik-beered-a-szelvedomosoval/"}
                       rel="noreferrer noopener"
                       target={"_blank"}>
                        <img src={"assets/tonic-2.jpg"} alt={"PMSz Tonik - Beéred a szélvédőmosóval?"}/>
                        <div className="btn-primary mt-2">Elolvasom</div>
                    </a><br/>
                    <a href={"https://pmsz.hu/hireink/pmsz-tonik-a-rehabos/"}
                       rel="noreferrer noopener"
                       target={"_blank"}>
                        <img src={"assets/tonic-3.jpg"} alt={"PMSz Tonik - A Rehabos"}/>
                        <div className="btn-primary mt-2">Elolvasom</div>
                    </a>
                </div>
            </>
    },
    {
        id: 4, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Cp4RRAEgpeU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Cp4RRAEgpeU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Tekints meg mi történik épp most, a Santa Claus village-ben.</h4>
            </>
    },
    {
        id: 5, content:
            <>
                <h3>Tavaszi PMSZ körkapcsolás</h3>
                <p>2023. júniusában tartottuk meg a 39. Körkapcsolás konferenciákat. Középpontba a fenntarthatóság
                    került. Fogadjátok szeretettel az itt készült rövid videónkat.</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls>
                        <source src="assets/videos/pmsz_fokuszban_a_fenntarthatosag_v2v.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/videos/pmsz_fokuszban_a_fenntarthatosag_v2v.mp4" type="video/mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 6, content: <>
            <>
                <h2>
                    Karácsonyfadísz ötletek újrahasznosítással</h2>
                <img src="./assets/recycle.png" alt="recycle" className={'my-4 mx-auto rounded-lg'}/>
                <h4 className={'text-center'}>Az újrahasznosításban az a szép, hogy még egy ilyen ünnepi tárgy is, mint
                    a karácsonyfadísz is készülhet a leghétköznapibb holmiból, akár hulladékból is.</h4>
                <p>Emlékszem, hogy mennyire ledöbbentem még október végén, amikor betérve egy nagy áruházba, már a
                    polcok fele karácsonyi termékekkel, díszekkel volt megpakolva. És persze buzgón válogattak köztük az
                    emberek. Én utoljára friss házasként, az első karácsonyfánkra vettem újonnan díszt. Azt is
                    viszonylag hamar megbántam, mert elég gyatra minőség volt, pedig nem annak tűnt elsőre. Azóta akadt
                    a kezembe pár karácsonyfadísz turiban és mellettük leginkább sajátkészítésűek ékesítik a fánkat. A
                    következő fokozat az lesz, hogy ezek is leginkább újrahasznosítással készüljenek, ehhez szedtem
                    össze ötleteket ezen a Pinterest táblán!</p>
                <div className={'flex justify-center'}>
                    <a href="https://hu.pinterest.com/zldnek/kar%C3%A1csonyfad%C3%ADszek-%C3%BAjrahasznos%C3%ADt%C3%A1ssal/"
                       target="_blank" rel={"noreferrer noopener"}
                       className="btn-primary w-fit px-5 px-2">Megnézem</a></div>
            </>
        </>
    },
    {
        id: 7, content: <>
            <h2 className={'mb-6'}>Karácsonyi ajándék csomagolás ötletek – 11 remek tipp kreatív és egyedi
                ajándékcsomagoláshoz</h2>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 py-6 border-y-2 '}>
                <div className={'order-1'}><img src="./assets/csomagolas-1.jpg"
                                                alt=""
                                                className={'h-auto max-w-[50%] mx-auto rounded'}/></div>
                <p className={'order-2 text-lg'}>A karácsonyi mintájú csomagolóanyagok között találhatunk szépeket,
                    de ha
                    valami eredetibbre
                    vágyunk, használhatunk teljesen egyszerű, sima csomagolópapírt, kössünk rá szalagot és díszítsük
                    fehér festékkel, levelekkel, ágakkal.</p>
            </div>

            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-2.jpg"
                                                           alt=""
                                                           className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 md:order-1 text-lg'}>Utánozhatjuk egy postai küldemény megjelenését egy
                    egyszerűbb csomagolópapírral, nagy postabélyegekkel, postabélyegzővel, színes zsinórral átkötve
                    – címezzük meg és írjuk alá, hogy teljes legyen a kép.</p>
            </div>

            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 '}><img src="./assets/csomagolas-3.jpg"
                                                 alt=""
                                                 className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 text-lg'}>A gyerekeknek biztosan örömet szerez majd, ha a
                    klasszikus módszerrel, ajándék tartó zokniba tesszük a kisebb meglepetéseket, ezeket horgokra
                    akasztva lógathatjuk is sorban a fa mellett.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-4.jpg"
                                                           alt=""
                                                           className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 md:order-1 text-lg'}>A kis ajándéktárgyak és édességek nagyszerűen néznek ki
                    szép üvegedényekben, díszítsük madzaggal és kézműves hatású üdvözlőkártyával – az üveg az
                    ajándékozás után is megmarad, emlékeztetőül a szép pillanatokra.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 '}><img src="./assets/csomagolas-5.jpg"
                                                 alt=""
                                                 className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 text-lg'}>Tegyük az ajándékokat előre elkészített vagy házi készítésű
                    dobozokba (utóbbi esetben keményebb kartonból mi is elkészíthetjük a dobozt egy az internetről
                    letöltött sablon alapján). A megvásárolható dobozok között biztosan találunk szépet, de egyedibb
                    lesz az ajándék, ha magunk csomagoljuk egy szép, skandináv mintájú papírba.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-6.jpg"
                                                           alt=""
                                                           className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 md:order-1 text-lg'}>Ha a doboz helyett valami érdekesebb formát szeretnénk,
                    az interneten találhatunk sablonokat tasakokhoz is, vastagabb, szép mintával díszített papírból
                    hajthatjuk össze.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 '}><img src="./assets/csomagolas-7.jpg"
                                                 alt=""
                                                 className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 text-lg'}>Engedjük szabadon a fantáziánkat, hiszen az ajándékokat nem
                    kötelező az unalomig ismert karácsonyi csomagolópapírokba burkolni. Használhatunk erre a célra
                    pl. térképet (utazók nagy örömére), de egy újságpapírból is remek dolgokat hozhatunk ki.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-8.jpg"
                                                           alt=""
                                                           className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 md:order-1 text-lg'}>Maradt valamennyi tapéta a lakásdekorációból?
                    Felhasználhatjuk azt is, de ha ügyesen bánunk a textilekkel, akkor egy maradék anyagból is
                    készíthetünk egyedi csomagdíszt.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                <div className={'order-1'}><img src="./assets/csomagolas-9.jpg"
                                                alt=""
                                                className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 text-lg'}>Ha valaki szeretne eltérni a szokványos piros, zöld,
                    télapós, fenyőfás, angyalkás csomagolástól, válasszon elegáns fekete-fehér csomagolópapírokat,
                    kreatív díszítéssel. A fűszerek, bogyók, tobozok szépen passzolnak a fekete-fehér
                    csomagoláshoz.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                <div className={'order-1 md:order-2'}><img src="./assets/csomagolas-10.jpg"
                                                           alt=""
                                                           className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 md:order-1 text-lg'}>Bármelyik csomagolást is választjuk, egy kis extra
                    díszítés nem árthat. Legyünk kreatívak, növényekkel, levelekkel, fenyőágakkal.</p>
            </div>
            <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                <div className={'order-1 '}><img src="./assets/csomagolas-11.jpg"
                                                 alt=""
                                                 className={'h-auto max-w-[50%] mx-auto rounded'}/>
                </div>
                <p className={'order-2 text-lg'}>Egy szép ezüst, fehér, bézs papírt nagyszerűen feldobhatunk kisebb
                    karácsonyi díszekkel, papír csillagokkal, szalagokkal, csipkékkel, textíliákkal és apró
                    figurákkal.</p>
            </div>
            <p className={'text-xs'}>Forrás: <a
                href={"https://lakbermagazin.hu/praktikus-lakberendezesi-otletek/2777-karacsonyi-ajandek-csomagolas-otletek-11-remek-tipp-kreativ-es-egyedi-ajandekcsomagolashoz.html"}
                rel={"noreferrer noopener"}
                target={"_blank"}>https://lakbermagazin.hu/praktikus-lakberendezesi-otletek/2777-karacsonyi-ajandek-csomagolas-otletek-11-remek-tipp-kreativ-es-egyedi-ajandekcsomagolashoz.html</a>
            </p>
        </>
    },
    {
        id: 8, content: <>
            <img src="assets/winnie.png" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
            <img src="assets/winnie_mobil.png" alt="" className={'rounded md:hidden'}/>

        </>
    },
    {
        id: 9, content: <>
            <h2>Vörösboros kacsacomb polentával</h2>
            <div>
                <img src="assets/voros-boros-kacsa.jpg" alt="" className={'my-4 rounded'}/>
            </div>
            <h4>Ha valami, hát ez egy igazi ünnepi étel. Lassan készül és ezért mély, gazdag ízeivel teljesen
                elvarázsol. Az édeskés-savanykás mártás mellé a krémes, puha polenta tökéletes köret.</h4>
            <div className="grid md:grid-cols-4 gap-x-2">
                <div>
                    <h3>Hozzávalók</h3>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>4 kacsacomb</li>
                        <li>só és bors</li>
                        <li>1 evőkanál olívaolaj</li>
                        <li>2,5 dl száraz vörös bor</li>
                        <li>10 dkg aszalt meggy</li>
                        <li>10 dkg aszalt feketecseresznye</li>
                        <li>5 dkg mazsola</li>
                        <li>1 fej fokhagyma</li>
                        <li>2 teáskanál kakukkfű</li>
                        <li>1 csipet cayenne bors</li>
                        <li>1 l szárnyas alaplé</li>
                        <li>2 teáskanál kukoricakeményítő</li>
                        <li>2 evőkanál kapribogyó</li>
                        <li>2 evőkanál finomra aprított petrezselyem</li>
                        <li>1 bio citrom héja és leve</li>
                    </ul>
                    <h4 className={'mb-2'}>A polentához:</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>20 dkg kukoricadara</li>
                        <li>1 l víz</li>
                        <li>7 dkg frissen reszelt parmezán</li>
                        <li>5 dkg vaj</li>
                        <li>1 tk só</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A kacsacombokon a bőrt beirdaljuk, majd sózzuk és borsozzuk őket. Az olajat közepes lángon
                        felhevítjük egy vaslábasban, majd bőrükkel lefelé rátesszük a kacsacombokat és kíméletesen
                        lepirítjuk őket (8-9 perc alatt). Euztán megfordítjuk a combokat, és további 5 percig
                        pirítjuk. A felesleges zsírt leöntjük és kiszedjük a combokat. A lábasba öntjük a bort,
                        felére beforraljuk, közben felkaparjuk a lábas aljára ragadt sült húsdarabokat. Hozzáadjuk
                        az aszalt gyümölcsöket, a fokhagymát, kakukkfüvet és a csilipelyhet. Visszatesszük a
                        kacsacombokat és felöntjük annyi alaplével, amennyi 2/3 részéig ellepi a húst.
                        Felforraljuk, majd áttesszük a 170 fokra előmelegített sütőbe, ahol 1,5 órá sütjük fedő
                        alatt.
                    </p>
                    <p>Ha puhára sültek, a kacsacombokat kivesszük a lábasból és melegen tartjuk. A lábasban maradt
                        mártásról leszedjük a zsírt, a mártást pedig felére beforraljuk, közben hozzáadjuk a
                        gyöngyhagymát. Ha túl híg a mártás, kikeverünk 1 ek kukoricakeményítőt 2 ek vörös borral és
                        a mártáshoz adjuk, addig főzzük, amíg az besűrűsödik. Végül beállítjuk az ízeket egy kevés
                        citromlével, sóval, borssal. Tálaláskor megszórjuk a maradék petrezselyemmel és krémes
                        polentát adunk mellé.
                    </p>
                    <p>
                        A polentához a vizet felforraljuk, sózzuk és megvárjuk, amíg újra felforr. Lassan
                        beleszórjuk a kukoricadarát és 5 percig keverjük közepesen alacsony láng felett. Néha
                        megkeverve addig főzzük, amig a dara megpuhul és elválik a lábos oldalától. Lehúzzuk a
                        tűzről, hozzákeverjük a puha vajat és a reszelt parmezánt. Tálalásig melegen tartjuk, a
                        tetejére nedves sütőpapírt teszünk, hogy ne bőrösödjön.
                    </p>
                </div>
            </div>
            <p className={'text-xs'}>Forrás: <a href={"https://lilafuge.hu/vorosboros-kacsacomb-polentaval/"}
                                                target={"_blank"}
                                                rel={"noreferrer noopener"}>https://lilafuge.hu/vorosboros-kacsacomb-polentaval/</a>
            </p>
        </>
    },
    {
        id: 10, content:
            <>
                <h2>40. PMSZ Körkapcsolás</h2>
                <p>A 2023. év őszén megrendezett 40. PMSZ Körkapcsolás igazán ünnepi volt. Az agilitás témakörében a
                    hazai szakemberek mellett nemzetközi szakértők tartottak előadásokat és izgalmas gyakorlati
                    workshopot. Fogadjátok szeretettel az itt készült rövid videónkat.</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/toSPfGnYyJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/toSPfGnYyJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    },
    {
        id: 11, content:
            <>
                <h2>Top10: kihagyhatatlan karácsonyi filmek decemberre</h2>
                <h4>Válassz a listában szereplő filmek közül, és hagyd, hogy magával ragadjon a karácsonyi
                    hangulat!</h4>
                <div className="grid ">
                    <div>
                        <div className="col-xs-12">


                            <p>1.<strong> Grincs </strong>– Jim Carrey, Kik, karácsony</p>

                            <p>2. <strong>Reszkessetek betörők</strong> – nincs karácsony
                                Kevin nélkül</p>

                            <p>3.<strong> Reszkessetek betörők 2</strong>. - továbbra sincs
                                karácsony Kevin nélkül</p>

                            <p>4. <strong>Reszkessetek betörők 3.</strong> - van karácsony
                                Kevin nélkül, de minek?!</p>

                            <p>5. <strong>Let It Snow</strong> - szabadnapos sztár és a
                                tipikus lány a szomszédból találkozása a Netflixen</p>

                            <p>6.&nbsp;<strong>Holiday</strong> – Amerika, Anglia, szerelem,
                                karácsony</p>

                            <p>7. <strong>Hull a pelyhes</strong> – Turbo Man, Arnold
                                Schwarzenegger, karácsonyi őrület</p>

                            <p>8. <strong>Igazából szerelem</strong> – kétségtelenül a
                                valaha volt legjobb karácsonyi film</p>

                            <p>9. <b>Last Christmas -&nbsp;</b>a sárkányok anyja,&nbsp;a
                                Crazy Rich Asians főszereplője, London és persze karácsony</p>
                            <p>10. <strong>Tapló télapó</strong>- a Mikulás bácsi bunkó,
                                faragatlan kiadása</p>
                        </div>
                    </div>
                </div>
            </>
    },
    {
        id: 12, content: <>
            <img src="assets/12-desktop.jpg" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
            <img src="assets/12-mobile.jpg" alt="" className={'rounded md:hidden'}/>
        </>
    },
    {
        id: 13, content:
            <>
                <h3>Ti hogy álltok az idei csapatmunkával? :)</h3>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fUXdrl9ch_Q"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/fUXdrl9ch_Q"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    },
    {
        id: 14, content: <>
            <img src="assets/14-desktop.jpg" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
            <img src="assets/14-mobile.jpg" alt="" className={'rounded md:hidden'}/>
        </>
    },
    {
        id: 15, content:
            <>
                <p>Ha épp eleged lett a karácsonyi sorban állás miatt a zöldségesnél vagy tetőfokára hágott a "kinek és
                    milyen ajándékot vegyek???" érzés, akkor nyugodj le. Fogd a fülesedet, csinálj egy forraltbort
                    magadnak és hallgass bele a 2023. év leghallgatottabb PMSZ podcastjaiba. Ez majd megnyugat. Ha
                    mégsem, akkor hallgass meg még egyet :)
                </p>
                <div className={'flex gap-3 flex-wrap md:flex-nowrap'}>
                    <a href={"https://pmsz.hu/hireink/pmsz-podcast-strategia-es-projektportfolio/"}
                       rel="noreferrer noopener"
                       target={"_blank"}>
                        <img src={"assets/podcast-3.jpg"} alt={""}/>
                        <div className="btn-primary mt-2">Meghallgatom</div>
                    </a>
                    <a href={"https://pmsz.hu/hireink/pmsz-podcast-a-vilag-legjobb-projektmenedzsere/"}
                       rel="noreferrer noopener"
                       target={"_blank"}>
                        <img src={"assets/podcast-2.jpg"} alt={"?"}/>
                        <div className="btn-primary mt-2">Meghallgatom</div>
                    </a><br/>
                    <a href={"https://pmsz.hu/hireink/pmsz-podcast-az-ember-a-projekt-mogott-a-magyar-zene-haza-sikerenek-titka/"}
                       rel="noreferrer noopener"
                       target={"_blank"}>
                        <img src={"assets/podcast-1.jpg"} alt={""}/>
                        <div className="btn-primary mt-2">Meghallgatom</div>
                    </a><br/>
                </div>
            </>
    },
    {
        id: 16, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/iDicwfAhDaM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/iDicwfAhDaM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <h4>Fogadd szeretettel ezt a videót :)</h4>
            </>
    },
    {
        id: 17, content: <>
            <img src="assets/17-desktop.jpg" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
            <img src="assets/17-mobile.jpg" alt="" className={'rounded md:hidden'}/>
        </>
    },
    {
        id: 18, content: <>
            <img src="assets/18-desktop.jpg" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
            <img src="assets/18-mobile.jpg" alt="" className={'rounded md:hidden'}/>
        </>
    },
    {
        id: 19, content:
            <>
                <div className={'flex max-w-[700px] mx-auto flex-col'}>
                    <h3>Van mire idén is büszkének lennünk!</h3>
                    <p>
                        <img src={"assets/ev-pm-dij.jpg"} alt={"Év Projektmenedzsere Díj"}
                             className={'float-left mr-2 mb-2'}/>
                        Idén is meghirdetésre került a 2023. év év
                        projektvezetője díj.<br/>
                        Az Év Projektmenedzsere Díjat idén Wolf András, a Market Építő Zrt. műszaki
                        vezérigazgató-helyettese
                        vehette át, aki a MOL Campus generálkivitelezését irányította műszaki igazgatóként,
                        projektvezetőként. A 2022. őszén átadott, 143 méter magas toronyház Magyarország
                        legmagasabb,
                        leginnovatívabb és legkörnyezettudatosabb épülete.<br/>
                        Különdíjat kapott Molnár Ákos András, a PriceWaterHouseCooper Magyarország Kft. vezető
                        menedzsere, a
                        thyssenkrupp budapesti fejlesztőközpontjában zajló agilis transzformáció
                        támogatásáért.<br/>
                        Szívből gratulálunk az elismeréshez, és további sikereket kívánunk!<br/>
                        Wolf Andrással készült interjút itt tekinthetitek meg:</p>
                    <div className={'md:flex justify-center my-4 hidden'}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/vqYMyvutDyA"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                    <div className={'flex justify-center my-4 md:hidden'}>
                        <iframe width="100%" height="250" src="https://www.youtube.com/embed/vqYMyvutDyA"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </div>
            </>
    },
    {
        id: 20, content:
            <>
                <h3>Project management assignment</h3>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/g86jbaFKLds"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/g86jbaFKLds"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <h4>Fogadd szeretettel ezt a videót :)</h4>
            </>
    },
    {
        id: 21, content: <>
            <img src="assets/21-desktop.jpg" alt="" className={'my-4 mx-auto rounded hidden md:block'}/>
            <img src="assets/21-mobile.jpg" alt="" className={'rounded md:hidden'}/>
        </>
    },
    {
        id: 22, content:
            <>
                <div>
                    <img src="assets/xmasglue.gif" alt=""/>
                </div>
            </>
    },
    {
        id: 23, content: <>
            <BallsGame/>
        </>
    },
    {
        id: 24, content:
            <>
                <h2>Karácsonyi üdvözlet</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls>
                        <source src="assets/videos/karacsonyi-udvozlet-1080.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/videos/karacsonyi-udvozlet-1080.mp4" type="video/mp4"/>
                    </video>
                </div>
            </>
    },
]
